/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for MKButton
import MKButtonRoot, {
  AppButtonProps,
  ButtonStyleStateProps,
} from "components/MKButton/MKButtonRoot";

type Props = AppButtonProps & Partial<ButtonStyleStateProps>;

const MKButton = forwardRef(
  (
    {
      color = "white",
      variant = "contained",
      size = "medium",
      circular = false,
      iconOnly = false,
      children,
      ...rest
    }: Props,
    ref
  ) => (
    <MKButtonRoot
      {...rest}
      ref={ref as React.RefObject<HTMLButtonElement>}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}
    >
      {children}
    </MKButtonRoot>
  )
);

export default MKButton;
