/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for MKTypography
import MKTypographyRoot, {
  AppTypographyProps,
  TypographyStyleStateProps,
} from "components/MKTypography/MKTypographyRoot";

type Props = AppTypographyProps & Partial<TypographyStyleStateProps>;

const MKTypography = forwardRef(
  (
    {
      color = "dark",
      fontWeight = false,
      textTransform = "none",
      verticalAlign = "unset",
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    }: Props,
    ref
  ) => (
    <MKTypographyRoot
      {...rest}
      ref={ref as React.Ref<HTMLSpanElement>}
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
      }}
    >
      {children}
    </MKTypographyRoot>
  )
);

export default MKTypography;
