/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import { useDeviceType } from "utils/useDeviceType";

interface Link {
  type: "github" | "webpage" | "youtube" | "documentation";
  href: string;
  title?: string;
}

type ProgLang = "C++" | "Golang" | "Python" | "Typescript" | "Swift" | "Ruby";

interface Framework {
  name: string;
  language?: ProgLang;
}

export interface ProjectItemDetail {
  image: string;
  title: string;
  description: string;
  date: { start: Date; end?: Date };
  links?: Link[];
  frameworks?: Framework[];
}

type Props = ProjectItemDetail;

function ProjectItem(props: Props) {
  const { image, title, description, links, frameworks } = props;
  const { isMobile, isDesktop } = useDeviceType();

  const linksComponent = (
    <MKBox display="flex">
      {links.map((link, i) => {
        const iconClassName = (() => {
          switch (link.type) {
            case "github":
              return "fab fa-github";
            case "webpage":
              return "fa fa-globe";
            case "youtube":
              return "fab fa-youtube";
            case "documentation":
              return "fa fa-file";
          }
        })();

        const title = (() => {
          switch (link.type) {
            case "github":
              return "Repository";
            case "webpage":
              return "Webpage";
            case "youtube":
              return "Video";
            case "documentation":
              return "Documentations";
          }
        })();
        return (
          <MKTypography
            key={i}
            component="a"
            variant="body1"
            color="dark"
            href={link.href}
            title={link.title !== undefined ? link.title : title}
            target="_blank"
            mr={3}
          >
            <i className={iconClassName} />
          </MKTypography>
        );
      })}
    </MKBox>
  );

  return (
    <Grid container item xs={12} md={10} mx={{ xs: "auto", sm: 6, md: 1 }} justifyContent="center">
      <Grid item xs={4} justifyContent="center" alignItems="center" alignContent="center">
        <MKAvatar
          src={image}
          shadow={isMobile ? "xl" : "xxl"}
          size={isMobile ? "xl" : "xxl"}
        ></MKAvatar>
      </Grid>
      <Grid item xs={8}>
        <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <MKTypography fontWeight="bold" variant="h4">
            {title}
          </MKTypography>
          {isDesktop ? linksComponent : null}
        </MKBox>
        {isMobile ? linksComponent : null}
        <MKTypography fontWeight="light" variant="h5">
          {description}
        </MKTypography>
        {frameworks !== undefined && (
          <MKTypography fontWeight="light" variant="h5">
            {"Frameworks: "}
            <MKTypography variant="body1" color="dark" mr={3}>
              {frameworks.map((framework, i) => {
                return [
                  `${framework.name}${
                    framework.language !== undefined ? ` (${framework.language})` : ""
                  }`,
                  i !== frameworks.length - 1 ? ", " : "",
                ];
              })}
            </MKTypography>
          </MKTypography>
        )}
      </Grid>
    </Grid>
  );
}

export default ProjectItem;
