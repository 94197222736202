/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
// import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/LandingPages/MainAuthor/sections/Profile";
import Projects from "pages/LandingPages/MainAuthor/sections/Projects";
import Footer from "pages/LandingPages/MainAuthor/sections/Footer";

// Routes
// import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";
import profilePicture from "assets/images/personal/profile-picture.jpg";
import AppCard from "components/AppCard";
import Icon from "@mui/material/Icon";

function MainAuthor() {
  const projectsRef = React.useRef(null);

  const routes = [
    {
      name: "Projects",
      icon: <Icon>article</Icon>,
      linkRef: projectsRef,
    },
  ];

  return (
    <>
      <DefaultNavbar brand={"DLAU"} routes={routes} transparent light />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKBox
            mt={{ xs: 16, md: 20 }}
            style={{ zIndex: 1 }}
            textAlign="center"
            alignItems="center"
          >
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxxl" shadow="xl" />
          </MKBox>
        </MKBox>
        <AppCard
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile />
          <Projects ref={projectsRef} />
        </AppCard>
        {/* <Contact /> */}
        <Footer />
      </MKBox>
    </>
  );
}

export default MainAuthor;
