import Card from "@mui/material/Card";
import { CardProps } from "@mui/material/Card";
import { SxProps, Theme } from "@mui/material/styles";
import { AppTheme } from "assets/theme";
import { forwardRef } from "react";

type Props = Omit<CardProps, "sx"> & { sx: SxProps<AppTheme> };

const AppCard = forwardRef(({ sx, ...props }: Props, ref) => (
  <Card ref={ref as React.Ref<HTMLDivElement>} sx={sx as SxProps<Theme>} {...props} />
));

export default AppCard;
