/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKAvatar from "components/MKAvatar";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

const RESUME_LINK =
  "https://drive.google.com/file/d/19EfUbwq2cNiPwwcJtqyNuNu_kEas3Set/view?usp=sharing";
const GITHUB_LINK = "https://github.com/daniellau88";
const LINKEDIN_LINK = "https://www.linkedin.com/in/daniel-lau-3a020519b";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Daniel Lau</MKTypography>
                <MKBox display="flex" justifyContent="center" alignItems="center">
                  <MKTypography
                    component="a"
                    variant="body1"
                    color="dark"
                    href={RESUME_LINK}
                    target="_blank"
                    title="Resume"
                    mr={3}
                  >
                    <i className="fa fa-file" />
                  </MKTypography>
                  <MKTypography
                    component="a"
                    variant="body1"
                    color="dark"
                    href={GITHUB_LINK}
                    target="_blank"
                    mr={3}
                    title="Github"
                  >
                    <i className="fab fa-github" />
                  </MKTypography>
                  <MKTypography
                    component="a"
                    variant="body1"
                    color="dark"
                    href={LINKEDIN_LINK}
                    target="_blank"
                    mr={3}
                    title="Linkedin"
                  >
                    <i className="fab fa-linkedin" />
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                Hi, I am a Final Year Computer Science student at NUS.
                <br />
                I am interested in Backend (Networking, DB Query Optimising), DevOps (Hosting on
                Digital Ocean, Managing Databases) and Programming Languages (Interpreters).
                <br />
                <br />
                Find out more about my projects below!!
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
