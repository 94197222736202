/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
import ProjectItem, { ProjectItemDetail } from "pages/LandingPages/MainAuthor/sections/ProjectItem";

import quiztown from "assets/images/personal/quiztown.png";
import cacademy from "assets/images/personal/c-academy.png";
import cardbox from "assets/images/personal/cardbox.png";
import carecorner from "assets/images/personal/carecorner.jpg";
import dracobot from "assets/images/personal/dracobot.jpg";
import { forwardRef } from "react";

const projects: ProjectItemDetail[] = [
  {
    image: cacademy,
    title: "CAcademy",
    date: { start: new Date("2023-03-25"), end: new Date("2023-04-16") },
    description:
      "CAcademy is a C interpreter built upon Source Academy. The application includes features such as visualization of the stack and heap during runtime.",
    links: [
      {
        type: "github",
        href: "https://github.com/daniellau88/cs4215-frontend",
        title: "Frontend Repository",
      },
      {
        type: "github",
        href: "https://github.com/daniellau88/c-slang",
        title: "Interpreter Repository",
      },
      {
        type: "documentation",
        href: "https://drive.google.com/file/d/1irN4LkM0DzdKtLSp-RPvDEFDkdEtg-j6/view?usp=sharing",
      },
      { type: "webpage", href: "https://c-academy.dlau.one/" },
    ],
    frameworks: [
      { name: "React", language: "Typescript" },
      { name: "Interpreter Backend", language: "Typescript" },
    ],
  },
  {
    image: cardbox,
    title: "CardBox",
    date: { start: new Date("2022-03-06"), end: new Date("2022-04-28") },
    description:
      "Cardbox is a sandbox for card game lovers, to play various card games on their tablet device (more specifically on the iPad). The underlying Card Game Engine can be adapted to different card games, some of which has been been implemented such as Exploding Kittens and Monopoly Deal.",
    links: [
      {
        type: "github",
        href: "https://github.com/cs3217-2122/group-project-cardbox",
      },
      {
        type: "webpage",
        href: "https://uvents.nus.edu.sg/event/20th-steps/module/CS3217/project/3",
      },
    ],
    frameworks: [{ name: "SwiftUI", language: "Swift" }, { name: "Firebase" }],
  },
  {
    image: quiztown,
    title: "QuizTown",
    date: { start: new Date("2021-09-26"), end: new Date("2021-12-01") },
    description:
      "QuizTown is a flashcard assistant that helps you create flashcards from diagrams in textbooks, notes or presentations. The app also implements the spaced repetition method to maximise the efficacy of your learning.",
    links: [
      { type: "github", href: "https://github.com/daniellau88/cs3216-quiztown" },
      { type: "youtube", href: "https://www.youtube.com/watch?v=i85dN0dSJVg&ab_channel=QuizTown" },
      {
        type: "documentation",
        href: "https://github.com/daniellau88/cs3216-quiztown/blob/main/docs/group-7-final-report.pdf",
      },
      {
        type: "webpage",
        href: "https://uvents.nus.edu.sg/event/19th-steps/module/CS3216/project/7",
      },
    ],
    frameworks: [
      { name: "React", language: "Typescript" },
      { name: "Django", language: "Python" },
      { name: "Redis" },
      { name: "MySQL" },
    ],
  },
  {
    image: dracobot,
    title: "DracoBot",
    date: { start: new Date("2020-08-09"), end: new Date("2021-12-01") },
    description:
      "DracoBot is a telegram bot that helps to facilitate message passing in an Angel Mortal game (aka Dragon Trainer). The bot has been used for 3 years consecutively, with each iteration having up to 50 players.",
    links: [{ type: "github", href: "https://github.com/daniellau88/dracobot2" }],
    frameworks: [{ name: "Python Telegram Bot", language: "Python" }, { name: "MySQL" }],
  },
  // {
  //   image: post1,
  //   title: "Chimera",
  //   date: { start: new Date("2021-09-12"), end: new Date("2021-10-01") },
  //   description:
  //     "Chimera is a flashcard assistant that helps you create flashcards from diagrams in textbooks, notes or presentations. The app also implements the spaced repetition method to maximise the efficacy of your learning.",
  //   links: [{ type: "documentation", href: "https://quiztown.dlau.one/" }],
  //   frameworks: [
  //     { name: "React", language: "Typescript" },
  //     { name: "Django", language: "Python" },
  //     { name: "Redis" },
  //     { name: "MySQL" },
  //   ],
  // },
  {
    image: carecorner,
    title: "Computing for Voluntary Welfare Organisations (CVWO)",
    date: { start: new Date("2020-05-01"), end: new Date("2020-07-01") },
    description:
      "At CVWO, we helped the Care Corner Counselling Center to maintain their Case Management System, which they use to manage their clients and cases. For this iteration, we did a frontend upgrade for the system, to make it more modern-looking and optimized.",
    links: [
      {
        type: "webpage",
        href: "https://www.comp.nus.edu.sg/~vwo/projects/2020-carecorner-cms.html",
      },
    ],
    frameworks: [
      { name: "React", language: "Typescript" },
      { name: "Ruby on Rails", language: "Ruby" },
      { name: "MySQL" },
    ],
  },
  // {
  //   image: post2,
  //   title: "Baggage Identifier",
  //   description: "Identify baggage",
  //   links: [],
  // },
  // {
  //   image: post2,
  //   title: "CVWO",
  //   description: "CVWO",
  //   links: [],
  // },
];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const Projects = forwardRef((props: Props, ref) => (
  <MKBox component="section" py={2} ref={ref}>
    <Container>
      <Grid container item xs={12} lg={6}>
        <MKTypography variant="h3" mb={6}>
          Projects
        </MKTypography>
      </Grid>
      <MKBox component="section" py={{ xs: 6, sm: 12 }}>
        <Container>
          <Grid container item xs={12} justifyContent="center" mx="auto" rowSpacing={10}>
            {projects.map((x, i) => {
              return <ProjectItem key={i} {...x} />;
            })}
          </Grid>
        </Container>
      </MKBox>
    </Container>
  </MKBox>
));

export default Projects;
