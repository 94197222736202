/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// Custom styles for MKAvatar
import MKAvatarRoot, {
  AppAvatarProps,
  AvatarStyleStateProps,
} from "components/MKAvatar/MKAvatarRoot";

type Props = AppAvatarProps & Partial<AvatarStyleStateProps>;

const MKAvatar = forwardRef(
  ({ bgColor = "transparent", size = "md", shadow = "none", ...rest }: Props, ref) => (
    <MKAvatarRoot
      ref={ref as React.RefObject<HTMLDivElement>}
      ownerState={{ shadow, bgColor, size }}
      {...rest}
    />
  )
);

export default MKAvatar;
